import BaseApiDetails from '../baseApiDetailsClass';
import RfqLineItemDetails from './rfqLineItemDetails';

export default class RfqDetails extends BaseApiDetails {
  url = 'rfq/rfq/';
  fields = [
    { name: 'id', def: null, readonly: true },

    { name: 'status', def: 'Draft' },
    { name: 'name' },
    { name: 'comp_name', readonly: true },
    { name: 'number', readonly: true },

    { name: 'project' },
    { name: 'project_info', readonly: true },
    { name: 'company' },
    { name: 'company_info', readonly: true },
    { name: 'sales_mgr' },
    { name: 'sales_mgr_info', readonly: true },
    { name: 'proj_mgr' },
    { name: 'proj_mgr_info', readonly: true },

    { name: 'ship_terms' },
    { name: 'ship_method' },

    { name: 'quote_due', type: 'date' },
    { name: 'delivery_date', type: 'date' },

    { name: 'domestic', def: false },
    { name: 'international', def: true },
    { name: 'req_alternate_source', def: false },

    { name: 'notes' },

    { name: 'updated', readonly: true, type: 'dateTime' },
    { name: 'created', readonly: true, type: 'dateTime' },
    { name: 'created_by', readonly: true },
    { name: 'created_by_info', readonly: true },

    { name: 'line_items', type: 'child_list', model: RfqLineItemDetails },
    // { name: 'responses', type: 'child_list', model: SlateRFQResponse },

    { name: 'legacy', def: false, readonly: true },

  ];

  constructor(id) {
    super(id);
    this.check();
    this.clear();
  }

  statusChoices = ['Draft', 'Ready', 'Quoting', 'Completed', 'Revision', 'Requote', 'Cancelled', 'Exception Hold'];
}
