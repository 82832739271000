import { use, registerComponent } from 'buefy/src/utils/plugins';
import Input from './Input.vue';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, Input);
  },
};

use(Plugin);

export default Plugin;

export {
  Input as NumberInput,
};
