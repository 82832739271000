import BaseApiDetails from '../baseApiDetailsClass';


export default class RfqResponseLineItemFlatCosts extends BaseApiDetails {
  fields = [
    { name: 'id' },

    { name: 'sort_order', def: 100 },
    { name: 'primary', def: false },

    { name: 'name' },
    { name: 'price', type: 'float', def: 0.0 },

    { name: 'updated', readonly: true, type: 'date' },
    { name: 'created', readonly: true, type: 'date' },
  ];

  constructor(id) {
    super(id);
    this.check();
    this.clear();
  }
}
