import { Big } from '@/utils/big';
import convert from 'convert-units';

import BaseApiDetails from '../baseApiDetailsClass';
import RfqResponseOptionDetails from './rfqResponseOptionDetails';
import RfqResponseFlatCostDetails from './rfqResponseFlatCostDetails';

export default class RfqResponseLineItemDetails extends BaseApiDetails {
  fields = [
    { name: 'id' },

    { name: 'sort_order', def: 100 },

    { name: 'name' },
    { name: 'desired_qty', type: 'float', readonly: true },
    { name: 'unit_name', readonly: true },

    { name: 'component', readonly: true },
    { name: 'component_info', readonly: true },

    { name: 'splits', def: [], type: 'json' },
    { name: 'options', type: 'child_list', model: RfqResponseOptionDetails },
    { name: 'flatcosts', type: 'child_list', model: RfqResponseFlatCostDetails },

    { name: 'carton_qty', type: 'float' },
    { name: 'carton_net_weight', type: 'float' },
    { name: 'carton_gross_weight', type: 'float' },
    { name: 'carton_weight_measure', def: 'kg' },
    { name: 'carton_length', type: 'float' },
    { name: 'carton_width', type: 'float' },
    { name: 'carton_height', type: 'float' },
    { name: 'carton_size_measure', def: 'mm' },

    { name: 'item_weight', type: 'float' },
    { name: 'item_weight_measure', def: 'kg' },

    { name: 'packout_method' },

    { name: 'specs' },

    { name: 'updated', readonly: true, type: 'date' },
    { name: 'created', readonly: true, type: 'date' },
  ];
  url = 'rfq/responses/';

  constructor(id) {
    super(id);
    this.check();
    this.clear();
  }

  availSizeMeasures = ['mm', 'cm', 'm', 'ft'];
  availWeightMeasures = ['kg', 'g', 'lb'];
  availPackoutMethods = ['bulk', 'flat', 'assembled', 'soldier', 'partitioned', 'foam', 'blister', 'layered'];

  addBreak() {
    let largest = Math.max(...this.splits);
    if (!Number.isInteger(largest)) {
      largest = this.desired_qty / 2;
    }
    if (Number.isNaN(largest)) {
      largest = this.desired_qty / 2;
    }
    this.splits.push(largest * 2);

    return this;
  }

  removeBreakByIndex(index) {
    this.splits.splice(index, 1);
  }

  sortBreaks() {
    this.splits.sort((a, b) => parseInt(a, 10) - parseInt(b, 10));
  }

  addOption() {
    const option = new RfqResponseOptionDetails();
    if (this.options.some((opt) => opt.name === 'New Option')) {
      return this;
    }
    option.loadFromPayload({
      name: 'New Option',
    });
    this.options.push(option);
    this.options.forEach((item, idx) => { item.sort_order = idx; });
    return this;
  }

  addFlatCost() {
    const flatcost = new RfqResponseFlatCostDetails();
    if (this.flatcosts.some((opt) => opt.name === 'New Flat Cost')) {
      return this;
    }
    flatcost.loadFromPayload({
      name: 'New Flat Cost',
    });
    this.flatcosts.push(flatcost);
    this.flatcosts.forEach((item, idx) => { item.sort_order = idx; });
    return this;
  }

  deleteFlatCost(idx) {
    this.flatcosts[idx].toDelete = true;
    return this;
  }

  calcCBMs() {
    if ([
      this.carton_height,
      this.carton_length,
      this.carton_width,
    ].some((item) => item === 0.0)) { return new Big(0); }
    const l = new Big(convert(this.carton_length).from(this.carton_size_measure).to('cm'));
    const w = new Big(convert(this.carton_width).from(this.carton_size_measure).to('cm'));
    const h = new Big(convert(this.carton_height).from(this.carton_size_measure).to('cm'));

    const cbmPerCarton = l.times(w).times(h).div(1000000);
    return cbmPerCarton;
  }

  calcTotalCBMs() {
    if ([
      this.desired_qty,
      this.carton_qty,
    ].some((item) => item === 0.0)) { return new Big(0); }

    const cbmPerCarton = this.calcCBMs();

    const desiredQty = new Big(this.desired_qty);
    const qtyPerCarton = new Big(this.carton_qty);
    const cartons = desiredQty.div(qtyPerCarton);

    const totalCbms = cartons.round(0, 3).times(cbmPerCarton);

    return totalCbms;
  }
}
