<template>
  <div class="control" :class="rootClasses">
    <input
      ref="input"
      class="input"
      :class="[inputClasses, customClass]"
      :type="newType"
      :autocomplete="newAutocomplete"
      :maxlength="maxlength"
      :value="computedValue"
      v-bind="$attrs"
      @input="onInput"
      @blur="onBlur"
      @focus="onFocus"
    />

    <b-icon
      v-if="icon"
      class="is-left"
      :class="{'is-clickable': iconClickable}"
      :icon="icon"
      :pack="iconPack"
      :size="iconSize"
      @click.native="iconClick('icon-click', $event)"
    />

    <b-icon
      v-if="!loading && hasIconRight"
      class="is-right"
      :class="{ 'is-clickable': passwordReveal || iconRightClickable }"
      :icon="rightIcon"
      :pack="iconPack"
      :size="iconSize"
      :type="rightIconType"
      both
      @click.native="rightIconClick"
    />

    <small
      v-if="maxlength && hasCounter && type !== 'number'"
      class="help counter"
      :class="{ 'is-invisible': !isFocused }"
    >{{ valueLength }} / {{ maxlength }}</small>
  </div>
</template>

<script>
import config from 'buefy/src/utils/config';
import FormElementMixin from 'buefy/src/utils/FormElementMixin';

import Cleave from 'cleave.js';

export default {
  name: 'NumberInput',
  components: {
  },
  mixins: [FormElementMixin],
  inheritAttrs: false,
  props: {
    value: [Number, String],
    decimals: {
      type: Number,
      default: 6,
    },
    type: {
      type: String,
      default: 'text',
    },
    passwordReveal: Boolean,
    iconClickable: Boolean,
    hasCounter: {
      type: Boolean,
      default: () => config.defaultInputHasCounter,
    },
    customClass: {
      type: String,
      default: '',
    },
    iconRight: String,
    iconRightClickable: Boolean,
  },
  data() {
    return {
      newValue: this.value,
      parsedValue: this.value,
      newType: this.type,
      newAutocomplete: this.autocomplete || config.defaultInputAutocomplete,
      isPasswordVisible: false,
    };
  },
  computed: {
    computedValue: {
      get() {
        return this.newValue;
      },
      set(value) {
        this.newValue = value;
        if (!this.isValid) {
          this.checkHtml5Validity();
        }
      },
    },
    rootClasses() {
      return [
        this.iconPosition,
        this.size,
        {
          'is-expanded': this.expanded,
          'is-loading': this.loading,
          'is-clearfix': !this.hasMessage,
        },
      ];
    },
    inputClasses() {
      return [this.statusType, this.size, { 'is-rounded': this.rounded }];
    },
    hasIconRight() {
      return this.passwordReveal || this.loading || this.statusTypeIcon || this.iconRight;
    },
    rightIcon() {
      if (this.passwordReveal) {
        return this.passwordVisibleIcon;
      }
      if (this.statusTypeIcon) {
        return this.statusTypeIcon;
      }
      return this.iconRight;
    },
    rightIconType() {
      if (this.passwordReveal) {
        return 'is-primary';
      }
      if (this.statusTypeIcon) {
        return this.statusType;
      }
      return null;
    },

    /**
     * Position of the icon or if it's both sides.
     */
    iconPosition() {
      if (this.icon && this.hasIconRight) {
        return 'has-icons-left has-icons-right';
      }
      if (!this.icon && this.hasIconRight) {
        return 'has-icons-right';
      }
      if (this.icon) {
        return 'has-icons-left';
      }
      return null;
    },

    /**
     * Icon name (MDI) based on the type.
     */
    statusTypeIcon() {
      switch (this.statusType) {
      case 'is-success':
        return 'check';
      case 'is-danger':
        return 'alert-circle';
      case 'is-info':
        return 'information';
      case 'is-warning':
        return 'alert';
      default:
        return null;
      }
    },

    /**
     * Check if have any message prop from parent if it's a Field.
     */
    hasMessage() {
      return !!this.statusMessage;
    },

    /**
     * Current password-reveal icon name.
     */
    passwordVisibleIcon() {
      return !this.isPasswordVisible ? 'eye' : 'eye-off';
    },
    /**
     * Get value length
     */
    valueLength() {
      if (typeof this.computedValue === 'string') {
        return this.computedValue.length;
      }
      if (typeof this.computedValue === 'number') {
        return this.computedValue.toString().length;
      }
      return 0;
    },
  },
  watch: {
    value(value) {
      this.newValue = value;
      this.cleave.setRawValue(this.newValue);
    },
  },
  methods: {
    /**
     * Input's 'input' event listener, 'nextTick' is used to prevent event firing
     * before ui update, helps when using masks (Cleavejs and potentially others).
     */
    onInput(event) {
      this.$nextTick(() => {
        if (event.target) {
          this.computedValue = event.target.value;
        }
      });
    },

    iconClick(emit, event) {
      this.$emit(emit, event);
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    },

    rightIconClick(event) {
      if (this.passwordReveal) {
        this.togglePasswordVisibility();
      } else if (this.iconRightClickable) {
        this.iconClick('icon-right-click', event);
      }
    },

    parseNumber(origValue, locale = navigator.language) {
      const value = `${origValue}`;
      const example = new Intl.NumberFormat(locale).format('1.1');
      const cleanPattern = new RegExp(`[^-+0-9${example.charAt(1)}]`, 'g');
      const cleaned = value.replace(cleanPattern, '');
      const normalized = cleaned.replace(example.charAt(1), '.');

      return parseFloat(normalized);
    },

    onValueChanged({ target }) {
      this.$nextTick(() => {
        this.newValue = target.value;
        this.$emit('input', target.rawValue);
      });
    },
  },
  mounted() {
    const options = {
      numeral: true,
      numeralThousandsGroupStyle: 'thousand',
      rawValueTrimPrefix: true,
      onValueChanged: this.onValueChanged.bind(this),
    };
    if (!Number.isNaN(this.decimals)) {
      options.numeralDecimalScale = this.decimals;
    }
    this.cleave = new Cleave(this.$el.querySelector('input'), options);

    this.cleave.setRawValue(this.newValue);
  },
};
</script>

<style lang="scss" scoped>
.input {
  text-align: right;
}
</style>
