<template>
  <article class="rfq-response-lineitem">
    <div class="columns">
      <h4 class="column">{{ item.component_info.composite_name }}</h4>
      <h5
        class="column is-4 has-text-right"
      >{{ $t('RFQS.RESPONSE.ITEMS.FIELDS.desired_qty') }}: {{ item.desired_qty.toLocaleString() }}</h5>
    </div>
    <hr />
    <div class="packoutspecs columns">
      <div class="column is-narrow">
        <div style="width: 6.4rem; margin-right: 0.6rem;">
          <slate-photo type="project" :id="item.component" />
        </div>
      </div>
      <div class="column">
        <h5>{{ $t('RFQS.RESPONSE.ITEMS.details') }}</h5>
        <hr />

        <table class="table is-fullwidth is-not-bordered">
          <thead>
            <tr>
              <th>{{ $t('RFQS.RESPONSE.ITEMS.FIELDS.name') }}</th>
              <th style="width: 16rem;">{{ $t('RFQS.RESPONSE.ITEMS.FIELDS.minimum_order') }}</th>
              <th v-for="(price_break, index) in item.splits" :key="index" style="width: 16rem;">
                <NumberInput
                  v-model.lazy="item.splits[index]"
                  @blur="item.sortBreaks()"
                  disabled="disabled"
                  :decimals="5"
                />
              </th>
              <th style="width: 16rem;">
                <!-- <button
                  class="button is-fullwidth is-primary"
                  @click="item.addBreak()"
                  :disabled="disabled"
                >
                  <icon icon="plus" />
                  <span>&nbsp;&nbsp;{{ $t('RFQS.RESPONSE.ITEMS.add_split') }}</span>
                </button> -->
                <button
                  class="button is-fullwidth is-primary"
                  @click="item.addOption()"
                  :disabled="disabled"
                >
                  <icon icon="plus" />
                  <span>&nbsp;&nbsp;{{ $t('RFQS.RESPONSE.ITEMS.add_option') }}</span>
                </button>

              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(option, index) in item.options" :key="index">
              <td>
                <b-input v-model="option.name" :readonly="option.primary" :disabled="disabled" />
                <div v-if="option.primary && item.specs">
                  <br />
                  <div v-html="item.specs"></div>
                  <br />
                </div>
              </td>
              <td>
                <number-input
                  v-model="option.minimum_order"
                  :decimals="5"
                  autocomplete="false"
                  :disabled="disabled"
                />
              </td>
              <td v-for="(price_break, index) in item.splits" :key="index">
                <number-input
                  v-model="option.prices[price_break]"
                  :decimals="5"
                  autocomplete="false"
                  :disabled="disabled"
                />
              </td>
              <td>
              </td>
            </tr>
          </tbody>
        </table>

        <h5>{{ $t('RFQS.RESPONSE.ITEMS.flat_costs') }}</h5>
        <hr />

        <table class="table is-fullwidth is-not-bordered">
          <thead>
            <tr>
              <th>{{ $t('RFQS.RESPONSE.ITEMS.FIELDS.flatcost_name') }}</th>
              <th style="width: 16rem;">
                {{ $t('RFQS.RESPONSE.ITEMS.FIELDS.flatcost_price') }}
              </th>
              <th style="width: 16rem;">
                <button
                  class="button is-fullwidth is-primary"
                  @click="item.addFlatCost()"
                  :disabled="disabled"
                >
                  <icon icon="plus" />
                  <span>&nbsp;&nbsp;{{ $t('RFQS.RESPONSE.ITEMS.add_flatcost') }}</span>
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(flatcost, index) in item.flatcosts" :key="index">
              <td><b-input v-model="flatcost.name" :disabled="disabled" /></td>
              <td><number-input
                  v-model="flatcost.price"
                  :decimals="5"
                  autocomplete="false"
                  :disabled="disabled"
                /></td>
                <td>
                  <!-- <button
                    class="button is-fullwidth"
                    @click="item.deleteFlatCost(index)"
                    :disabled="disabled"
                  >
                    <icon icon="trash-alt" />
                    <span>&nbsp;&nbsp;{{ $t('RFQS.RESPONSE.ITEMS.delete_flatcost') }}</span>
                  </button> -->
                </td>
            </tr>
            <tr v-if="!item.flatcosts.length">
              <td colspan="3"><h5>{{ $t('RFQS.RESPONSE.ITEMS.no_flatcosts') }}</h5></td>
            </tr>
          </tbody>

        </table>

        <h5>{{ $t('RFQS.RESPONSE.ITEMS.FIELDS.packout_specs') }}</h5>
        <hr />

        <div class="packoutspecs columns">
          <div class="column is-3">
            <b-field :label="$t('RFQS.RESPONSE.ITEMS.FIELDS.item_weight')">
              <b-field class="has-text-right">
                <b-input
                  v-model="item.item_weight"
                  type="number"
                  step="0.00001"
                  expanded
                  autocomplete="false"
                  :disabled="disabled"
                />
                <b-select v-model="item.item_weight_measure" :disabled="disabled">
                  <option
                    v-for="(measure) in item.availWeightMeasures"
                    :key="measure"
                    :value="measure"
                  >{{ $t(`APP.UNITOFMEASURE.WEIGHT.${measure}`) }}</option>
                </b-select>
              </b-field>
            </b-field>

            <b-field :label="$t('RFQS.RESPONSE.ITEMS.FIELDS.carton_qty')">
              <b-input
                v-model="item.carton_qty"
                type="number"
                autocomplete="false"
                :disabled="disabled"
              />
            </b-field>

            <b-field :label="$t('RFQS.RESPONSE.ITEMS.FIELDS.carton_net_weight')">
              <b-field class="has-text-right">
                <b-input
                  v-model="item.carton_net_weight"
                  type="number"
                  step="0.00001"
                  expanded
                  autocomplete="false"
                  :disabled="disabled"
                />
                <b-select v-model="item.carton_weight_measure" :disabled="disabled">
                  <option
                    v-for="(measure) in item.availWeightMeasures"
                    :key="measure"
                    :value="measure"
                  >{{ $t(`APP.UNITOFMEASURE.WEIGHT.${measure}`) }}</option>
                </b-select>
              </b-field>
            </b-field>

            <b-field :label="$t('RFQS.RESPONSE.ITEMS.FIELDS.carton_gross_weight')">
              <b-field class="has-text-right">
                <b-input
                  v-model="item.carton_gross_weight"
                  type="number"
                  step="0.00001"
                  expanded
                  autocomplete="false"
                  :disabled="disabled"
                />

                <b-select v-model="item.carton_weight_measure" :disabled="disabled">
                  <option
                    v-for="(measure) in item.availWeightMeasures"
                    :key="measure"
                    :value="measure"
                  >{{ $t(`APP.UNITOFMEASURE.WEIGHT.${measure}`) }}</option>
                </b-select>
              </b-field>
            </b-field>
          </div>
          <div class="column is-3">
            <b-field :label="$t('RFQS.RESPONSE.ITEMS.FIELDS.carton_height')">
              <b-field class="has-text-right">
                <b-input
                  v-model="item.carton_height"
                  type="number"
                  expanded
                  autocomplete="false"
                  :disabled="disabled"
                />
                <b-select v-model="item.carton_size_measure" :disabled="disabled">
                  <option
                    v-for="(measure) in item.availSizeMeasures"
                    :key="measure"
                    :value="measure"
                  >{{ $t(`APP.UNITOFMEASURE.LENGTH.${measure}`) }}</option>
                </b-select>
              </b-field>
            </b-field>

            <b-field :label="$t('RFQS.RESPONSE.ITEMS.FIELDS.carton_width')">
              <b-field class="has-text-right">
                <b-input
                  v-model="item.carton_width"
                  type="number"
                  expanded
                  autocomplete="false"
                  :disabled="disabled"
                />
                <b-select v-model="item.carton_size_measure" :disabled="disabled">
                  <option
                    v-for="(measure) in item.availSizeMeasures"
                    :key="measure"
                    :value="measure"
                  >{{ $t(`APP.UNITOFMEASURE.LENGTH.${measure}`) }}</option>
                </b-select>
              </b-field>
            </b-field>

            <b-field :label="$t('RFQS.RESPONSE.ITEMS.FIELDS.carton_length')">
              <b-field class="has-text-right">
                <b-input
                  v-model="item.carton_length"
                  type="number"
                  expanded
                  autocomplete="false"
                  :disabled="disabled"
                />
                <b-select v-model="item.carton_size_measure" :disabled="disabled">
                  <option
                    v-for="(measure) in item.availSizeMeasures"
                    :key="measure"
                    :value="measure"
                  >{{ $t(`APP.UNITOFMEASURE.LENGTH.${measure}`) }}</option>
                </b-select>
              </b-field>
            </b-field>
          </div>
          <div class="column is-3">
            <b-field :label="$t('RFQS.RESPONSE.ITEMS.FIELDS.packout_method')">
              <b-select v-model="item.packout_method" expanded :disabled="disabled">
                <option hidden disabled>{{ $t('RFQS.RESPONSE.PACKOUTMETHODS.select_method') }}</option>
                <option
                  v-for="(method) in item.availPackoutMethods"
                  :key="method"
                  :value="method"
                >{{ $t(`RFQS.RESPONSE.PACKOUTMETHODS.${method}`) }}</option>
              </b-select>
            </b-field>

            <b-field :label="$t('RFQS.RESPONSE.ITEMS.FIELDS.carton_cbms')">
              <b-input
                :value="item.calcCBMs().toFixed(2)"
                type="number"
                readonly
                :disabled="disabled"
              />
            </b-field>

            <b-field :label="$t('RFQS.RESPONSE.ITEMS.FIELDS.total_cbms')">
              <b-input
                :value="item.calcTotalCBMs().toFixed(2)"
                type="number"
                readonly
                :disabled="disabled"
              />
            </b-field>
          </div>
        </div>
      </div>
    </div>

    <br />
  </article>
</template>

<script>
import { NumberInput } from '@/components/numberInput';
import slatePhoto from './photo.vue';

export default {
  name: 'rfqResponseEditLineitem',
  components: { NumberInput, slatePhoto },
  props: {
    item: Object,
    disabled: Boolean,
  },
  methods: {
  },
};
</script>
