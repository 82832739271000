<template>
  <b-select expanded :value="value" @input="$emit('input', $event)">
    <option v-for="option in ports" :value="option" :key="option">{{ option }}</option>
  </b-select>
</template>

<script>
import freightLocations from '../../api/rates/freight.locations';

export default {
  name: 'FreightLocationSearchbox',
  props: ['value', 'locationType'],
  data: () => ({
    ports: [],
    freightLocations,
  }),
  methods: {
    getPorts() {
      if (!this.locationType) {
        return this.ports;
      }
      freightLocations.getLocations().then((locations) => {
        this.ports = locations[`${this.locationType}s`].filter((item) => item && item.length > 0);
      });
      return this.ports;
    },
  },
  watch: {
    freightLocations: {
      handler() {
        this.getPorts();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style>
</style>
