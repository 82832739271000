import axios from 'axios';

class SlateFreightEstimateLocations {
  apiBaseUrl = process.env.VUE_APP_API_URL ? process.env.VUE_APP_API_URL : '/api/v1/';
  url = 'rates/ports/';

  locations = {};
  promise = null;
  ttl = null;

  constructor() {
    this.getLocations();
  }

  getAPIUrl() {
    const url = `${this.apiBaseUrl}${this.url}`;
    return url;
  }

  getLocations() {
    if (this.promise) { return this.promise; }

    const params = {};

    this.promise = new Promise((resolve, reject) => {
      axios.get(this.getAPIUrl(), {
        params,
      }).then((response) => {
        this.ttl = setTimeout(() => {
          this.ttl = null;
          this.promise = null;
        }, 300000);

        this.locations = response.data.payload;
        resolve(response.data.payload);
      }).catch((error) => {
        this.promise = null;
        this.ttl = null;
        reject(error);
      });
    });

    return this.promise;
  }
}


const freightEstimateLocations = new SlateFreightEstimateLocations();

export default freightEstimateLocations;
