<template>
  <list-page>
    <div class="page-filters" v-if="slateAuth.perms.rfq_view_all">
      <div class="columns">
        <div class="text-filter column">
          <b-field :label="$t('RFQS.FIELDS.number')">
            <b-input v-model="filters.rfq" expanded />
          </b-field>
        </div>

        <div class="text-filter column">
          <b-field :label="$t('RFQS.FIELDS.vendor')">
            <b-input v-model="filters.vendor" expanded />
          </b-field>
        </div>
      </div>
      <br />
    </div>

    <b-table :data="rfqResponses.list" :loading="loading">
      <template v-slot:default="props">
        <b-table-column
          v-for="column in columns"
          :key="column.fieldName"
          :field="column.fieldName"
          :label="column.label"
          :class="column.class"
          :visible="activeColumns.includes(column.fieldName)"
          :width="column.width"
          sortable
        >
          <span v-if="column.to">
            <router-link
              :to="resolveTo(column, props.row)"
              :class="column.fieldClass"
              >{{ resolveData(column, props.row) }}</router-link
            >
          </span>
          <span v-else-if="column.fieldName == 'notification_count'"
            ><icon icon="comments fa-fw" v-if="props.row.notification_count"
          /></span>
          <span v-else :class="column.fieldClass">{{
            resolveData(column, props.row)
          }}</span>
        </b-table-column>
      </template>
    </b-table>
    <hr />
    <b-pagination
      :total="rfqResponses.totalItems"
      :current.sync="filters.page"
      :per-page="filters.count"
      range-before="2"
      range-after="2"
    >
    </b-pagination>
  </list-page>
</template>

<script>
import { get } from 'lodash';
import RfqResponseList from '../../api/rfq/rfqResponseList';

const editLink = { name: 'rfqResponseEdit', params: { id: 'id' } };

export default {
  name: 'SlateRfqResponsePage',
  components: {},
  data: () => ({
    columns: [
      { label: 'Status', fieldName: 'status', fieldClass: { tag: true, 'is-info': true } },
      {
        label: 'RFQ#',
        fieldName: 'rfq_info.number',
        class: '{mono}',
        to: editLink,
      },
      { label: '', fieldName: 'notification_count', width: '2.4rem' },
      { label: 'Vendor', fieldName: 'vendor_info.name' },
      { label: 'Name', fieldName: 'rfq_info.name', to: editLink },
      { label: 'Quote Due By', fieldName: 'rfq_info.quote_due', type: 'date' },
    ],
    activeColumns: ['status', 'rfq_info.number', 'notification_count', 'rfq_info.name', 'rfq_info.quote_due'],
    loading: false,
    rfqResponses: new RfqResponseList(),
    filters: {
      fields: ['status', 'name', 'notification_count', 'rfq_info', 'vendor', 'vendor_info'],
      page: 1,
      count: 50,
    },
  }),
  methods: {
    getField: get,
    resolveData(column, obj) {
      const objValue = get(obj, column.fieldName);
      if (column.type === 'date' && objValue) {
        return objValue.toLocaleDateString(undefined, { dateStyle: 'medium' });
      }
      if (column.fieldName === 'status') {
        return this.$t(`RFQS.RESPONSE.STATUS.${objValue}`);
      }

      return objValue;
    },
    resolveTo(column, obj) {
      if (typeof column.to === 'string') {
        return column.to;
      }
      const to = {};
      to.name = column.to.name;
      to.params = {};
      Object.keys(column.to.params).forEach((item) => {
        to.params[item] = get(obj, column.to.params[item]);
      });
      return to;
    },
    getData() {
      this.loading = true;
      this.rfqResponses
        .getList(this.filters)
        .then(() => {
          this.loading = false;
        })
        .catch((error) => {
          if (error.cancelled) {
            return this;
          }

          throw error;
        });
    },
  },
  watch: {
    filters: {
      handler() {
        this.getData();
      },
      deep: true,
      immediate: true,
    },
    'slateAuth.perms.rfq_view_all': {
      handler() {
        if (this.slateAuth.perms.rfq_view_all && this.activeColumns.indexOf('vendor_info.name') < 0) {
          this.activeColumns.splice(this.activeColumns.indexOf('rfq_info.name'), 0, 'vendor_info.name');
        }
      },
      immediate: true,
    },
  },
  mounted() {

  },
};
</script>
