<template>
  <page :loading="loading" :key="response.id">
    <template v-slot:header>
      <header class="columns">
        <div class="column is-2">
          <b-field :label="$t('RFQS.FIELDS.number')">
            <b-input
              v-model="response.rfq_info.number"
              size="is-large"
              readonly
              :disabled="!response.isEditable"
            />
          </b-field>
        </div>
        <div class="column">
          <b-field :label="$t('RFQS.FIELDS.name')">
            <b-input
              v-model="response.rfq_info.name"
              size="is-large"
              readonly
              :disabled="!response.isEditable"
            />
          </b-field>
        </div>
        <div class="column is-2">
          <b-field :label="$t('RFQS.RESPONSE.FIELDS.status')">
            <b-select
              v-model="response.status"
              size="is-large"
              expanded
              :disabled="!response.isEditable"
            >
              <option value disabled hidden>{{ $t('RFQS.RESPONSE.FIELDS.status_placeholder') }}</option>
              <option
                v-for="option in response.availStatusChoices"
                :value="option"
                :key="option"
                :disabled="!response.validStatusChoices.includes(option)"
              >{{ $t(`RFQS.RESPONSE.STATUS.${option}`) }}</option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field label="‎">
            <!-- There is a unicode l-to-r mark in that string -->

            <button
              class="button is-primary is-large is-fullwidth"
              @click="saveData();"
              :disabled="saving || !response.isEditable"
            >
              <icon v-if="saving" icon="spinner fa-spin" />
              <icon v-else icon="save" />
              <span v-if="saving">{{ $t('APP.SAVING') }}</span>
              <span v-else>{{ $t('APP.SAVE') }}</span>
            </button>
          </b-field>
        </div>
      </header>
    </template>

    <template v-slot:bare>
      <section class="obj-details columns">
        <div class="column">
          <div class="box">
            <div class="columns">
              <div class="column">
                <b-field :label="$t('RFQS.RESPONSE.ITEMS.FIELDS.quote_expires')">
                  <b-datepicker
                    v-model="response.quote_expires"
                    icon-right="calendar-day"
                    :min-date="minDate"
                    :disabled="!response.isEditable"
                  ></b-datepicker>
                </b-field>
                <b-field :label="$t('RFQS.RESPONSE.ITEMS.FIELDS.leadtime')">
                    <b-input
                      v-model="response.leadtime"
                      expanded
                      :disabled="!response.isEditable"
                    />
                </b-field>
                <b-field :label="$t('RFQS.RESPONSE.ITEMS.FIELDS.sample_leadtime')">
                    <b-input
                      v-model="response.sample_leadtime"
                      expanded
                      :disabled="!response.isEditable"
                    />
                </b-field>
              </div>
              <div class="column">
                <b-field :label="$t('RFQS.RESPONSE.FIELDS.inco_terms')">
                  <b-select v-model="response.inco_terms" expanded :disabled="!response.isEditable">
                    <option
                      v-for="(terms) in response.availIncoTerms"
                      :key="terms"
                      :value="terms"
                    >{{ $t(`APP.INCOTERMS.${terms}`) }}</option>
                  </b-select>
                </b-field>
                <b-field :label="$t('RFQS.RESPONSE.FIELDS.inco_terms_location')">
                    <b-input
                      v-model="response.inco_terms_location"
                      expanded
                      :disabled="!response.isEditable"
                    />
                </b-field>
                <b-field :label="$t('RFQS.RESPONSE.FIELDS.origin_port_verbose')">
                    <location-selector v-model="response.origin_port" location-type="origin_port" />
                </b-field>
              </div>
            </div>

            <h4>{{ $t('APP.NOTES') }}</h4>
            <hr />
            <div class="field">
              <ckeditor
                :editor="editor"
                v-model="response.notes"
                :config="editorConfig"
                :disabled="!response.isEditable"
              ></ckeditor>
            </div>
          </div>
          <div class="box">
            <h4>{{ $t('APP.FILES') }}</h4>
            <div class="columns">
              <div class="column">
                <h6>{{ $t('RFQS.RESPONSE.FILES_FROM_RFQ') }}</h6>
                <hr />
                <slate-file-attachments
                  parent-type="rfq"
                  :parent-fk="response.rfq"
                  ref="rfqFileArea"
                />
                <br />
                <h6>{{ $t('RFQS.RESPONSE.FILES_FROM_RESPONSE') }}</h6>
                <hr />
                <slate-file-attachments
                  parent-type="rfqresponse"
                  :parent-fk="response.id"
                  ref="responseFileArea"
                />
              </div>
              <div class="column">
                <h6>{{ $t('RFQS.RESPONSE.UPLOAD_FILES') }}</h6>
                <hr />
                <slate-file-uploads
                  parent-type="rfqresponse"
                  :parent-fk="response.id"
                  ref="responseUploader"
                ></slate-file-uploads>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-4">
          <div class="box" v-if="response.status == 'rejected'">
            <h4>{{ $t('RFQS.RESPONSE.REJECTION.rejected') }}</h4>
            <hr>
            <div>
              <span><label class="label">{{ $t('RFQS.RESPONSE.REJECTION.reason') }}:</label></span>
              <span>{{ $t(`RFQS.RESPONSE.REJECTION.REASONS.${response.rejection_reason}`) }}</span>
            </div>
            <br>
            <div>
              <span><label class="label">{{ $t('RFQS.RESPONSE.REJECTION.notes') }}:</label></span>
              <div v-html="response.rejection_notes"></div>
            </div>
            <br>
          </div>

          <div class="box">
            <h5>
            <strong>{{ $t('RFQS.RESPONSE.FIELDS.PROJECTMANAGER') }}:</strong>&nbsp;
            <span>{{ response.rfq_info.proj_mgr_info.full_name }}&nbsp;</span>
            <a :href="`mailto:${ response.rfq_info.proj_mgr_info.email }?subject=RFQ %23${response.rfq_info.number} ${encodeURIComponent(response.rfq_info.name)}`"><icon icon="envelope" /></a></h5>

            <br>

            <h5><strong>{{ $t('RFQS.RESPONSE.FIELDS.PMNOTES') }}</strong></h5>
            <hr />
            <div v-html="response.rfq_info.notes"></div>
            <br>
          </div>

          <div class="box">
            <h4>{{ $t('APP.DISCUSSIONS') }}</h4>
            <hr />
            <slate-discussions parent-type="rfqresponse" :parent-fk="response.id" />
          </div>
        </div>
      </section>

      <section class="rfq-items box" v-for="(item) in response.line_items" :key="item.id">
        <rfq-response-edit-lineitem :item="item" :disabled="!response.isEditable" />
      </section>
    </template>

    <template v-slot:footer>
      <section class="columns">
        <div class="column is-2" v-if="response.validStatusChoices.includes('declined')">
          <button
            class="button is-danger is-large is-fullwidth"
            @click="saveDeclineData();"
            :disabled="saving || !response.isEditable"
          >
            <icon v-if="saving" icon="spinner fa-spin" />
            <icon v-else icon="frown" />
            <span>{{ $t('RFQS.RESPONSE.DECLINED.decline') }}</span>

          </button>&nbsp;&nbsp;
        </div>
        <div class="column is-2">
          <button
            class="button is-warning is-large is-fullwidth"
            @click="voidData();"
            :disabled="saving || !response.isEditable || !response.isVoidable"
          >
            <icon v-if="saving" icon="spinner fa-spin" />
            <icon v-else icon="copy" />
            <span>{{ $t('APP.VOID_COPY') }}</span>

          </button>
        </div>
        <div class="column"></div>
        <div class="column is-2">
          <button
            class="button is-primary is-large is-fullwidth"
            @click="saveData();"
            :disabled="saving || !response.isEditable"
          >
            <icon v-if="saving" icon="spinner fa-spin" />
            <icon v-else icon="save" />
            <span v-if="saving">{{ $t('APP.SAVING') }}</span>
            <span v-else>{{ $t('APP.SAVE') }}</span>
          </button>
        </div>
        <div class="column is-2" v-if="response.validStatusChoices.includes('ready')">
          <button
            class="button is-primary is-large is-fullwidth"
            @click="saveSubmitData();"
            :disabled="saving || !response.isEditable"
          >
            <icon v-if="saving" icon="spinner fa-spin" />
            <icon v-else icon="share-square" />
            <span v-if="saving">{{ $t('APP.SAVING') }}</span>
            <span v-else>{{ $t('APP.SUBMIT') }}</span>

            <span></span>
          </button>
        </div>
      </section>
    </template>
  </page>
</template>

<script>
import CKEditor from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import RfqResponse from '@/api/rfq/rfqResponseDetails';

import slateDiscussions from '@/modules/discussions/embedded/discussion.vue';
import slateFileAttachments from '@/modules/filebrowser/attachments/filebrowser.vue';
import slateFileUploads from '@/modules/filebrowser/uploader/filebrowser.uploader.vue';

import rfqResponseEditLineitem from './rfq.response.edit.lineitem.vue';

import locationSelector from '../rates/freight.locations.searchbox.vue';

export default {
  name: 'SlateRfqResponseEdit',
  props: ['id'],
  components: {
    // slatePhoto,
    ckeditor: CKEditor.component,
    rfqResponseEditLineitem,
    slateDiscussions,
    slateFileAttachments,
    slateFileUploads,
    locationSelector,
  },
  data: () => ({
    loading: false,
    errorLoading: false,
    saving: false,
    errorSaving: false,
    saveTimeout: null,
    saveTimeoutSnack: null,

    editor: ClassicEditor,
    editorConfig: {
      // The configuration of the editor.
    },
    minDate: new Date(new Date().getTime() + (3 * 24 * 60 * 60 * 1000)), // 3 days out.

    response: new RfqResponse(),
  }),
  methods: {
    getData(id) {
      this.loading = true;
      if (!(this.response instanceof RfqResponse)) {
        this.response = new RfqResponse();
      }
      this.response.loadFromServer(id).then(() => {
        this.loading = false;
      });
    },
    saveData() {
      if (!this.response.isEditable) {
        return false;
      }
      this.saving = true;

      if (this.saveTimeout) { clearTimeout(this.saveTimeout); }
      this.saveTimeout = setTimeout(this.alertSaveTimeout, 8000);

      return this.response.saveToServer().then(() => {
        if (this.saveTimeout) { clearTimeout(this.saveTimeout); this.saveTimeout = null; }
        if (this.saveTimeoutSnack) { this.saveTimeoutSnack.close(); }
        this.$refs.responseUploader.upload().then(() => {
          setTimeout(() => {
            this.$refs.rfqFileArea.updateData();
            this.$refs.responseFileArea.updateData();

            this.saving = false;
            this.$buefy.snackbar.open({
              message: 'Response Saved',
              queue: false,
            });
          }, 300);
        }).catch(() => {
          if (this.saveTimeout) { clearTimeout(this.saveTimeout); this.saveTimeout = null; }
          if (this.saveTimeoutSnack) { this.saveTimeoutSnack.close(); }

          this.error = true;
          this.saving = false;
          this.$buefy.snackbar.open({
            message: 'Error Uploading',
            type: 'is-danger',
            queue: false,
          });
        });
      }).catch((error) => {
        if (error.cancelled) { return false; }
        this.error = true;
        this.saving = false;
        this.$buefy.snackbar.open({
          message: 'Error Saving',
          type: 'is-danger',
          queue: false,
        });
        return false;
      });
    },
    retrySaveData() {
      if (this.saveTimeoutSnack) { this.saveTimeoutSnack.close(); this.saveTimeoutSnack = null; }
      this.saveData();
    },
    alertSaveTimeout() {
      this.saveTimeoutSnack = this.$buefy.snackbar.open({
        message: this.$t('APP.SAVE_TIMEOUT'),
        actionText: this.$t('APP.RETRY'),
        type: 'is-danger',
        queue: false,
        indefinite: true,
        onAction: this.retrySaveData,
      });
    },
    voidData() {
      if (!this.response.isVoidable) { return false; }
      this.saving = true;
      return this.response.voidObject().then(() => {
        this.$buefy.snackbar.open({
          message: 'Response Voided and Duplicated',
          queue: false,
        });
        setTimeout(() => {
          this.saving = false;
          this.$router.push({ name: 'rfqResponseEdit', params: { id: this.response.id } });
        }, 300);
      }).catch(() => {
        this.error = true;
        this.saving = false;
        this.$buefy.snackbar.open({
          message: 'Error Voiding',
          type: 'is-danger',
          queue: false,
        });
      });
    },
    saveSubmitData() {
      this.response.status = 'ready';
      return this.saveData();
    },
    saveDeclineData() {
      this.response.status = 'declined';
      // eslint-disable-next-line no-alert
      this.response.declined_notes = prompt(this.$t('RFQS.RESPONSE.DECLINED.declined_reason'));
      return this.saveData();
    },
    removeBreak(item, index) {
      // Buefy focuses the input on a icon-click during the next tick. We need
      // remove the price break after that since the input goes away
      this.$nextTick(() => {
        item.removeBreakByIndex(index);
      });
    },
  },
  created() {
    this.getData(this.$route.params.id);
  },
};
</script>

<style lang="scss" scoped>
.obj-details {
  margin-bottom: calc(2.4rem - 0.75rem);
}
</style>
