import BaseApiDetails from '../baseApiDetailsClass';


export default class RfqResponseLineItemDetails extends BaseApiDetails {
  fields = [
    { name: 'id' },

    { name: 'sort_order', def: 100 },
    { name: 'primary', def: false },
    { name: 'name_editable', def: false },

    { name: 'name' },
    { name: 'prices', def: {}, type: 'json' },
    { name: 'minimum_order', def: 1, type: 'float' },

    { name: 'updated', readonly: true, type: 'date' },
    { name: 'created', readonly: true, type: 'date' },
  ];

  constructor(id) {
    super(id);
    this.check();
    this.clear();
  }
}
