<template>
  <span>
    <img :src="photo_entry" @click="isImageModalActive = true" style="max-width: 100%; border-radius:5%;" />
    <b-modal :active.sync="isImageModalActive">
            <p class="image is-square">
                <img :src="full_photo_entry">
            </p>
        </b-modal>
  </span>
</template>

<script>
import axios from 'axios';

export default {
  name: 'slatePhoto',
  props: ['id', 'type'],
  data: () => ({
    photo_entry: {},
    full_photo_entry: {},
    isImageModalActive: false,
  }),
  methods: {
    getData() {
      axios
        .get('/api/v1/profilephotos/get-thumb/', {
          params: { parent_type: this.type, parent_fk: this.id },
          headers: { authorization: this.slateAuth.token },
        })
        .then((response) => {
          this.photo_entry = response.data;
        });
      axios
        .get('/api/v1/profilephotos/get-thumb/', {
          params: { parent_type: this.type, parent_fk: this.id, size: 'original' },
          headers: { authorization: this.slateAuth.token },
        })
        .then((response) => {
          this.full_photo_entry = response.data;
        });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
