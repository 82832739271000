import BaseApiDetails from '../baseApiDetailsClass';


export default class RfqLineItemDetails extends BaseApiDetails {
  url = 'rfq/lineitems/';
  fields = [
    { name: 'id', readonly: true },
    { name: 'rfq' },
    { name: 'sort_order' },

    { name: 'component' },
    { name: 'component_info', readonly: true },

    { name: 'qty', type: 'float', def: 0 },
    { name: 'unit_name', def: 'ea' },

    { name: 'category' },
    { name: 'material' },

    { name: 'splits', type: 'json', def: [] },
    { name: 'options', type: 'json', def: [] },

    { name: 'specs' },

    { name: 'created', readonly: true },
    { name: 'updated', readonly: true },
    { name: 'deleted', readonly: true, default: false },

  ];

  constructor(id) {
    super(id);
    this.check();
    this.clear();
  }
}
