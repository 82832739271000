import axios from 'axios';

import SlateAuth from '@/api/auth/auth';
import BaseApiDetails from '../baseApiDetailsClass';
import RfqDetails from './rfqDetails';
import RfqResponseLineItemDetails from './rfqResponseLineItemDetails';


export default class RfqResponseDetails extends BaseApiDetails {
  fields = [
    { name: 'id' },

    { name: 'status', def: 'draft' },
    { name: 'quote_expires', type: 'date' },
    { name: 'leadtime' },
    { name: 'sample_leadtime' },

    { name: 'inco_terms' },
    { name: 'inco_terms_location' },

    { name: 'rfq', readonly: true },
    {
      name: 'rfq_info',
      type: 'related',
      model: RfqDetails,
      def: {},
      readonly: true,
    },

    { name: 'vendor' },
    { name: 'vendor_info', readonly: true },

    { name: 'notes' },

    { name: 'notification_count', def: 0 },

    { name: 'rejection_reason' },
    { name: 'rejection_notes' },
    { name: 'declined_notes' },

    { name: 'origin_port', def: '' },

    { name: 'submitted_time', readonly: true, type: 'date' },

    { name: 'line_items', type: 'child_list', model: RfqResponseLineItemDetails },

    { name: 'updated', readonly: true, type: 'date' },
    { name: 'created', readonly: true, type: 'date' },
    { name: 'created_by', readonly: true },
    { name: 'created_by_info', readonly: true },
  ];
  url = 'rfq/responses/';

  constructor(id) {
    super(id);
    this.check();
    this.clear();
  }

  availStatusChoices = ['draft', 'pending', 'ready', 'declined', 'accepted', 'rejected', 'expired', 'cancelled', 'void'];
  get validStatusChoices() {
    const choices = [this.baseStatus];
    if (this.baseStatus === 'draft') { choices.push('pending', 'declined'); }
    if (this.baseStatus === 'pending') { choices.push('ready', 'declined'); }
    if (SlateAuth.perms.rfq_response_can_set_approval) {
      if (this.baseStatus === 'ready') { choices.push('accepted', 'rejected'); }
    }
    return choices;
  }
  availIncoTerms = ['exw', 'fca', 'fob', 'cif', 'cpt', 'cip', 'dat', 'dap', 'ddp', 'ddu', 'other'];

  loadFromPayload(payload) {
    super.loadFromPayload(payload);
    this.baseStatus = this.status;
  }

  get isEditable() {
    if (['accepted', 'rejected', 'declined'].includes(this.baseStatus)) {
      return false;
    }
    return true;
  }

  get isVoidable() {
    if (!['pending', 'ready', 'rejected'].includes(this.baseStatus)) {
      return false;
    }
    return true;
  }

  voidObject() {
    return new Promise((resolve, reject) => {
      if (!this.isAuthed()) { return reject(new Error('Not logged in')); }
      const url = `${this.getAPIUrl()}void/`;
      axios.post(url).then((response) => {
        this.loadFromPayload(response.data.payload);
        if (response.data.permissions) {
          this.permissions = response.data.permissions;
        }
        resolve(this);
      }).catch((error) => {
        reject(error);
      });

      return this;
    });
  }
}
